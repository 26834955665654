export const environment = {
  production: true,
  cognito: {
    userPoolId: 'eu-west-2_IjZxN3nY7',
    userPoolWebClientId: 'hkaovuau9sel2kmkhlkvv2qbd',
  },
  fileSearchBaseUrl:
    'https://7z0g3aydqa.execute-api.eu-west-2.amazonaws.com/v2',
  quickSightApiEndPoint:
    'https://8rzyxj4ej1.execute-api.eu-west-2.amazonaws.com/v1',
  surveyUploadBucket: 'odl-test-raw-bucket',
  gisdataUrl: 'https://biodiversity-gis-data.s3.eu-west-2.amazonaws.com',
  portalConfiguration: {
    surveyThemes: {
      name: 'Test_SurveyThemes',
      path: 'survey-themes',
    },
    projectPhases: {
      name: 'Test_ProjectPhases',
      path: 'project-phases',
    },
    windfarms: {
      name: 'Test_CountryWindFarm',
      path: 'windfarms',
    },
    categories: {
      name: 'Test_Categories',
      path: 'categories',
    },
    confidencepolicies: {
      name: 'Test_Confidentiality',
      path: 'confidencepolicies',
    },
    usecase: {
      name: 'Test_UseCase',
      path: 'usecases',
    },
  },
};
